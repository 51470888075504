body.ring-plaza-bg {
    background: url('../images/ring-plaza-bg.jpg') no-repeat center center;
    height: 100vh;
    width: 100vw;
    footer.footer{
        padding: .5rem 0;
    }
    /*    background-size: cover;*/
}

.headroom--not-top{
    #nav-logo-hosting{
        background: url('../images/hosting-logo-white.svg') no-repeat center;
        background-size: contain;
        &.maroon-overlay{
            background: url('../images/hosting-logo-white.svg') no-repeat center;
            background-size: contain;
            height:14rem;
        }
    }
}



#nav-logo-hosting{
    padding-top: 10px;
    width:24.04137931034rem;
    height: 14rem;
    background: url('../images/hosting-logo-webmaroon.svg') no-repeat center, rgba(255,255,255,.6);
    background-size:contain;
    border: 1rem solid rgba(255,255,255,.6);
    border-image-slice: 1;
    border-radius:20px;
    background-clip:padding-box;
    &.maroon-overlay{
        height: 14rem;
        width: 24.04137931034rem;
        border: 1rem solid rgba(86,38,38,.7);
        background: url('../images/hosting-logo-white.svg') no-repeat center, rgba(86,38,38,.7);
        background-size: contain;
        background-clip:padding-box;
    }
    margin: 0 auto;
}

div.link-logo:hover{
    cursor:pointer;
}

div#tafs_long_maroon,h6#tafs_long_maroon{
    margin-top: 7px;
    width:auto;
    height: 3.5rem;
    background: url('../images/tafs_long_maroon.png') no-repeat center; 
    background-size: contain;
    margin-bottom:.5em;
}

.anh-page {
    overflow: visible;
    @include media-breakpoint-between(xs,md)
    {
        .card-anh{
            margin-top: -200px;
        }
    }
    @include media-breakpoint-between(xs,sm){
        .card-anh{
            h5,.h5{
                font-size:1.13rem;
            }
        }
    }
    @include media-breakpoint-up(lg){
        .card-anh{
            margin-top:-350px;
        }
    }
    .card-anh {
        background-color: rgba(255,255,255,.9);
        margin-bottom: 10em;
        .card-anh-heading {
            padding: 1rem 0;
            > div {
                text-align: center;
                margin-right: 1rem;
                padding: .875rem;

                &:last-child {
                    margin-right: 0;
                }

                .heading {
                    font-size: 1.1rem;
                    font-weight: bold;
                    display: block;
                }
                .description {
                    font-size: .875rem;
                    color: $gray-500;
                }
            }
        }

        .card-profile-actions {
            padding: .875rem;
        }

        @include media-breakpoint-down(xs) {
            .card-profile-actions {
                margin-top: 110px;
            } 
        }

        @include media-breakpoint-between(sm, md) {
            .card-profile-stats {
                margin-top: 30px;
            }
        }
    }
}