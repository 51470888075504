/*
Copyright (C) The Association of Former Students - All Rights Reserved
Unauthorized copying of this code, via any medium is strictly prohibited
Proprietary and confidential
*/
/*
    Author     : Evan McClintock
*/

@each $color,$value in $theme-colors {
    .tafs-tooltip-#{$color}.bs-tooltip-top, .tafs-tooltip-#{$color}.bs-tooltip-right,.tafs-tooltip-#{$color}.bs-tooltip-bottom,.tafs-tooltip-#{$color}.bs-tooltip-left,.tafs-tooltip-#{$color}.bs-tooltip-auto {       

        // Wrapper for the tooltip content
        .tooltip-inner {
            color: $tooltip-color;
            text-align: center;
            background-color: theme-color($color);
        }
    }
    .tafs-tooltip-#{$color}.bs-tooltip-top,.tafs-tooltip-#{$color}.bs-tooltip-auto[x-placement^="top"] {
        .arrow{
            &::before {                
                border-top-color: theme-color($color);
            }
        }
    }
    .tafs-tooltip-#{$color}.bs-tooltip-left,.tafs-tooltip-#{$color}.bs-tooltip-auto[x-placement^="left"] {
        .arrow{
            &::before {                
                border-left-color: theme-color($color);
            }
        }
    }
    .tafs-tooltip-#{$color}.bs-tooltip-right,.tafs-tooltip-#{$color}.bs-tooltip-auto[x-placement^="right"] {
        .arrow{
            &::before {                
                border-right-color: theme-color($color);
            }
        }
    }
    .tafs-tooltip-#{$color}.bs-tooltip-bottom,.tafs-tooltip-#{$color}.bs-tooltip-auto[x-placement^="bottom"] {
        .arrow{
            &::before {                
                border-bottom-color: theme-color($color);
            }
        }
    }

}
