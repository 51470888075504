/*
Copyright (C) The Association of Former Students - All Rights Reserved
Unauthorized copying of this code, via any medium is strictly prohibited
Proprietary and confidential
*/
/*
    Author     : Evan McClintock
*/

@each $color,$value in $theme-colors {
    .tafs-text-#{$color}-dark, .tafs-text-#{$color}-d10{
        color: darken(theme-color($color),10%);
    }
    .tafs-text-#{$color}-d5{
        color: darken(theme-color($color),5%);
    }

    .tafs-#{$color}.custom-control-input {
        &:active~.custom-control-label::before {
            border-color: theme-color($color);
        }
    }
    // Checkboxes
    .tafs-#{$color}.custom-checkbox {
        .custom-control-input {
            &:checked {
                ~ .custom-control-label {
                    &::before {
                        border-color: theme-color($color);
                    }                
                }
            }
            &:disabled {           
                &:checked {
                    &::before {
                        border-color: rgba(theme-color($color), .5);
                    }
                }
            }
        }

        .custom-control-input:checked ~ .custom-control-label {
            &::before {
                @include gradient-bg(theme-color($color));
            }            
        }

        .custom-control-input:disabled {
            &:checked ~ .custom-control-label::before {
                background-color: rgba(theme-color($color), .5);
            }
            &:indeterminate ~ .custom-control-label::before {
                background-color: rgba(theme-color($color), .5);
            }
        }

    }

    // Radios
    .tafs-#{$color}.custom-radio {   
        .custom-control-input {
            &:checked {
                ~ .custom-control-label {
                    &::before {
                        border-color: theme-color($color);
                    }                
                }
            }

            &:disabled {           
                &:checked {
                    &::before {
                        border-color: rgba(theme-color($color), .5);
                    }
                }
            }
        }

        .custom-control-input:checked ~ .custom-control-label {
            &::before {
                @include gradient-bg(theme-color($color));
            }            
        }

        .custom-control-input:disabled {
            &:checked ~ .custom-control-label::before {
                background-color: rgba(theme-color($color), .5);
            }
        }

    }

    // Toggles
    .tafs-#{$color}.custom-toggle {
        input {
            &:checked {
                + .custom-toggle-slider {
                    border: $custom-control-indicator-border-width solid theme-color($color);
                    &:before {
                        background: theme-color($color);
                    }  
                }
            }

            &:disabled {
                &:checked {
                    + .custom-toggle-slider {                  
                        &:before {
                            background-color: lighten(theme-color($color), 10%);
                        }
                    }
                }
            }
        }
    }

}
